body {
  line-height: 1;
}

ol,
ul {
  list-style: none;
}

blockquote,
q {
  quotes: none;
}

blockquote:before,
blockquote:after,
q:before,
q:after {
  content: "";
  content: none;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

input {
  display: block;
  margin: 0px;
}

h1,
h2,
h3,
p,
h4,
small {
  padding: 0px !important;
  margin-bottom: 0px !important;
  font-family: "Chevin", sans-serif !important;
}

a {
  font-family: "Chevin";
}

h1 {
  font-size: 28px !important;
}

h2 {
  font-size: 22px !important;
}

h3 {
  font-size: 16px !important;
}

/*own css */

:root {
  /* spacing */
  --app-spacing: 24px;
  --app-medium-spacing: 16px;

  /* colors */
  --gray: #dcdcdc;
  --light-gray: #fafafa;
  --op-orange: #ee5a00;

  /* layout variables */
  --bottom-nav-height-mobile: 70px;
  --bottom-nav-height-desktop: 100px;
}

html,
body {
  margin: 0px;
  height: 100%;
}

#footer {
  position: fixed;
  bottom: 0;
  width: 100%;
}

button {
  font-family: "Chevin" !important;
}

nav {
  padding: var(--app-padding);
  background-color: lightgray;
}

.tile-card {
  border-right: 1px solid var(--gray);
  border-left: 1px solid var(--gray);
}

.centeredContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  overflow-x: hidden;
  overflow-y: hidden;
  position: relative;
}

.user-routes-container {
  width: 100%;
  max-width: 800px;
  max-height: 100%;
  overflow: hidden;
  margin-left: auto;
  margin-right: auto;
  padding-bottom: var(--bottom-nav-height-desktop);
}

.app-side-padding {
  padding-right: var(--app-spacing);
  padding-left: var(--app-spacing);
}

.width-100 {
  width: 100% !important;
}

.button-fullwidth-mobile {
  width: 100% !important;
}

.responsive-container {
  width: 100%;
}

.services-name-button-container {
  display: flex;
}

.vertical-center {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
  resize: vertical;
}

.absolute {
  position: absolute;
}

.white {
  color: white !important;
}

.gray-background {
  background-color: var(--light-gray);
}

.text-center {
  margin: 0 auto;
  text-align: center;
}

.inline-block {
  display: inline-block;
}

.app-padding {
  padding: var(--app-spacing);
}

.app-medium-padding {
  padding: var(--app-medium-spacing);
}

.side-padding {
  padding-right: var(--app-spacing);
  padding-left: var(--app-spacing);
}

.app-margin {
  margin: var(--app-spacing);
}

#selected-location-or-building-header {
  padding-top: 0px;
  line-height: normal;
}

.height-100 {
  height: 100%;
}

.relative {
  position: relative;
}

/* ^ own css ^ */

.pointer {
  cursor: pointer;
}

.flex {
  display: flex;
}

.font-600 {
  font-weight: 600;
}

.font-700 {
  font-weight: 700;
}

.flex-1 {
  flex: 1;
}

.flex-2 {
  flex: 2;
}

.flex-3 {
  flex: 3;
}

.flex-4 {
  flex: 4;
}

.left {
  float: left;
}

.right {
  float: right;
}

.relative {
  position: relative;
}

.opacity-05 {
  opacity: 0.3;
}

//
// Automatic margins between child elements

.child-margins-x-4>*+* {
  margin-left: 4px;
}

.child-margins-x-8>*+* {
  margin-left: 8px;
}

.child-margins-x-12>*+* {
  margin-left: 12px;
}

.child-margins-x-16>*+* {
  margin-left: 16px;
}

.child-margins-x-20>*+* {
  margin-left: 20px;
}

.child-margins-x-24>*+* {
  margin-left: 24px;
}

.child-margins-x-32>*+* {
  margin-left: 32px;
}

.child-margins-x-48>*+* {
  margin-left: 48px;
}

.child-margins-x-64>*+* {
  margin-left: 64px;
}

.child-margins-x-128>*+* {
  margin-left: 128px;
}

.child-margins-y-2>*+* {
  margin-top: 2px;
}

.child-margins-y-4>*+* {
  margin-top: 4px;
}

.child-margins-y-8>*+* {
  margin-top: 8px;
}

.child-margins-y-12>*+* {
  margin-top: 12px;
}

.child-margins-y-16>*+* {
  margin-top: 16px;
}

.child-margins-y-20>*+* {
  margin-top: 20px;
}

.child-margins-y-24>*+* {
  margin-top: 24px;
}

.child-margins-y-32>*+* {
  margin-top: 32px;
}

.child-margins-y-48>*+* {
  margin-top: 48px;
}

.child-margins-y-64>*+* {
  margin-top: 64px;
}

.child-margins-x-128>*+* {
  margin-top: 128px;
}

.child-margins-x-hairline>*+* {
  margin-left: 1px;
}

.child-margins-y-hairline>*+* {
  margin-top: 1px;
}

/* Paddings */

.padding-hairline {
  padding: 1px;
}

.padding-4 {
  padding: 4px;
}

.padding-8 {
  padding: 8px;
}

.padding-12 {
  padding: 12px;
}

.padding-16 {
  padding: 16px;
}

.padding-20 {
  padding: 20px;
}

.padding-24 {
  padding: 24px;
}

.padding-28 {
  padding: 24px;
}

.padding-32 {
  padding: 32px;
}

.padding-48 {
  padding: 48px;
}

.padding-64 {
  padding: 64px;
}

.padding-128 {
  padding: 128px;
}

.padding-top {
  padding-right: 0;
  padding-bottom: 0;
  padding-left: 0;
}

.padding-right {
  padding-top: 0;
  padding-bottom: 0;
  padding-left: 0;
}

.padding-bottom {
  padding-top: 0;
  padding-right: 0;
  padding-left: 0;
}

.padding-left {
  padding-top: 0;
  padding-right: 0;
  padding-bottom: 0;
}

.padding-x {
  padding-top: 0;
  padding-bottom: 0;
}

.padding-y {
  padding-left: 0;
  padding-right: 0;
}

.padding-top-0 {
  padding-top: 0;
}

.padding-right-0 {
  padding-right: 0;
}

.padding-bottom-0 {
  padding-bottom: 0;
}

.padding-left-0 {
  padding-left: 0;
}

.padding-0 {
  padding: 0;
}

//
// Margins
.margin-auto {
  margin: auto;
}

.margin-auto-x {
  margin-left: auto;
  margin-right: auto;
}

.margin-auto-y {
  margin-top: auto;
  margin-bottom: auto;
}

.margin-hairline {
  margin: 1px;
}

.margin-2 {
  margin: 2px;
}

.margin-4 {
  margin: 4px;
}

.margin-8 {
  margin: 8px;
}

.margin-12 {
  margin: 12px;
}

.margin-16 {
  margin: 16px;
}

.margin-20 {
  margin: 20px;
}

.margin-24 {
  margin: 24px;
}

.margin-32 {
  margin: 32px;
}

.margin-48 {
  margin: 48px;
}

.margin-64 {
  margin: 64px;
}

.margin-128 {
  margin: 128px;
}

.negative-margin-2 {
  margin: -2px;
}

.negative-margin-4 {
  margin: -4px;
}

.negative-margin-8 {
  margin: -8px;
}

.negative-margin-12 {
  margin: -12px;
}

.negative-margin-16 {
  margin: -16px;
}

.negative-margin-20 {
  margin: -20px;
}

.negative-margin-24 {
  margin: -24px;
}

.negative-margin-32 {
  margin: -32px;
}

.negative-margin-48 {
  margin: -48px;
}

.negative-margin-64 {
  margin: -64px;
}

.negative-margin-128 {
  margin: -128px;
}

.margin-top {
  margin-right: 0;
  margin-bottom: 0;
  margin-left: 0;
}

.margin-right {
  margin-top: 0;
  margin-bottom: 0;
  margin-left: 0;
}

.margin-bottom {
  margin-top: 0;
  margin-right: 0;
  margin-left: 0;
}

.margin-left {
  margin-top: 0;
  margin-right: 0;
  margin-bottom: 0;
}

.margin-x {
  margin-top: 0;
  margin-bottom: 0;
}

.margin-y {
  margin-left: 0;
  margin-right: 0;
}

.margin-top-0 {
  margin-top: 0;
}

.margin-right-0 {
  margin-right: 0;
}

.margin-bottom-0 {
  margin-bottom: 0;
}

.margin-left-0 {
  margin-left: 0;
}

.margin-0 {
  margin: 0;
}

* {
  font-family: Chevin;
}
