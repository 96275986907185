.login-container {
  margin-left: 24px;
  margin-right: 24px;
  display: flex;
  height: 100vh;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.login-icon-container {
  border: 2px solid #a4a4a4;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  margin-right: 12px;
}

@media only screen and (min-width: 769px) {
  .login-container {
    width: 350px;
    margin-left: auto;
    margin-right: auto;
  }
}
