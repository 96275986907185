.navigation-user-navitems-container {
  width: 100%;
  max-width: 800px;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  height: var(--bottom-nav-height-mobile);
}

.navigation-link {
  width: 100%;
  max-width: 100px;
  display: grid;
  grid-template-columns: auto;
  justify-items: center;
  margin: 0 5px 0 5px;
  background-image: none !important;

  svg {
    height: 35px;
    width: 35px;
  }
}

.navigation-link:hover svg {
  transition: transform 0.1s ease-in-out;
  transform: translate(0px, -3px);
}

.navigation-user-tile {
  background-color: white;
  position: fixed;
  bottom: 0;
  width: 100%;
  z-index: 1000;
  box-shadow: 0 0 .2rem 0 rgba(0, 0, 0, .32), 0 .2rem .2rem 0 rgba(0, 0, 0, .2);
}

.navigation-admin-tile {
  h2 {
    margin-bottom: 24px !important;
  }
}

.navigation-admin-tile .ds-tile__content {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.navigation-admin-tile-main-navigation {
  align-self: flex-start;
}

.navigation-admin-tile-secondary-navigation {
  align-self: flex-end;
}

@media screen and (max-width: 500px) {
  .nav-item-label {
    font-size: 1.25rem !important;
  }

  .navigation-link {
    svg {
      height: 30px;
      width: 30px;
    }
  }
}