#top-title {
  padding-top: 0px !important;
  margin-bottom: 0px !important;
  height: 65px;
}

.top-title-tile {
  height: var(--bottom-nav-height-mobile);
}

@media only screen and (min-width: 769px) {
  #top-title {
    height: var(--bottom-nav-height-desktop);
  }
  .top-title-tile {
    height: 100px;
  }
}
